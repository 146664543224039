<template>
  <div class="confirmation-container">
    <div class="section">
      <div class="container">
        <h1 class="title is-3">Thanks for adding your GPT!</h1>
        <div class="box">
          <p>
            It will be shortly available on

            <a href="https://gpts.reviews" target="_blank">GPTs.reviews</a> and all our partner sites.
          </p>

          <br />
          <div class="buttons">
            <!-- Write a Review Button -->
            <router-link :to="'/gpts/' + $route.params.gptId + '/write-a-review'" class="button is-dark"
              >Write the first review</router-link
            >

            <!-- View GPT Button (External Link) -->
            <a :href="'https://gpts.reviews/g/' + $route.params.gptId" target="_blank" class="button">View page</a>

            <!-- Go Back Button -->
            <div class="button is-darkNO" @click="goBack">Edit GPT details</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    /*
    viewResults() {
      const gptId = this.$route.params.gptId;
      this.$router.push("/OTHERSITE.com/" + gptId);
    },
    writeReview() {
      const gptId = this.$route.params.gptId;
      this.$router.push("/write-review/" + gptId);
    },*/
  },
};
</script>

<style scoped>
.confirmation-container {
  /* Add your styles here */
}
</style>
